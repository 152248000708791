import React, { useState, useEffect } from 'react'
import { graphql } from "gatsby"

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import "../../styles/main.scss"

const DigiPrime = () => {
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 600)
  }, [])

  return loader ? (
    <div className="loader" />
  ) : (
    <Layout>
      <SEO
        title="Forcera"
        keywords={[`software development`, `ai`, `big data`, `cloud engineering`, `machine learning`, `data strategy`]}
      />
      <article>
        <div className="container">
          <h2>PACE - Public Administration Circular Economy</h2>
          <figure>
            <img
             src={"/digiprime.png"}
            />
          </figure>
          <p>
          <a className="a-blue" href="https://www.digiprime.eu/" target="_blank">Digiprime</a> is a dynamic Digital Manufacturing Platform for Connected Circular Smart Factories that embraces the principles of Circular Economy, which seeks to maximize resource utilization across industries.
          </p>
          <p>
          It has the mission to develop a new concept of Circular Economy digital platform overcoming current information asymmetry among value-chain stakeholders, in order to unlock new circular business models based on the data-enhanced recovery and re-use of functions and materials from high value-added post-use products with a cross-sectorial approach.
          </p>
          <p>          
          The key assumption of DigiPrime, thoroughly validated through six cross-sectorial pilots and further detailed in twenty use-cases covering five different European industrial sectors, is that through a properly designed and implemented digital platform, collaboration and data sharing among stakeholders can be promoted thus making it possible to boost currently untapped high-added value cross-sectorial circular business cases. 
          </p>
          <hr />
          <h3>Public Administration Circular Economy - PACE</h3>
          <p className="mt-2">
          The PACE sub-project will complement the DigiPrime platform with a novel Public Administration Pilot. By fomenting the concept of Circular Economy to the Public Administration sector, this sub- project will address the current information asymmetry between value-chain stakeholders, unlocking new circular and cross-sectorial business approaches based on post-use of end-of-life products that can be tracked through open Public Procurement contracting data.
          </p>
          <p>
          As public contracting is the world’s largest marketplace (Open Contracting Partnership—OCP), the Public Administration Pilot will make use of DigiPrime’s Cross-Sector Value-Chain Oriented Services and Operational Services to provide federated interoperable functions based on integrated open data to empower a use case-rich pilot, in a significant societal sector, aligned with the objectives of the European Green Deal, and championed by an SME specialized in public sector innovation solutions.
          </p>
          <figure>
            <img
             src={"/digiprime_public_administration.png"}
            />
          </figure>
          <p>
          As Public Administration makes up a sizeable portion of European society—14% of the EU's GDP in 2020 (Eurostat)—it significantly affects the manufacturing value chain. In fact, public authorities are main buyers in several industrial sectors, including energy, transport, waste management, and social protection. Products purchased by government organizations have a limited lifespan, just like all products. However, public institutions frequently lack a clear strategy for handling end-of- life products. As a result, considering environmental and even monetary considerations, the lifecycle of goods acquired by the government is not maximized. Further, individual public entities on their own lack the tools and resources to make a transformative change such as adopting Circular Economy policies. For these reasons, the circularity and innovation potential in the Public Administration sector is substantial.
          </p>
          <h3>Expected Impact</h3>
          <p>The Public Administration Pilot will positively impact DigiPrime’s promotion of Circular Economy in cross-sectorial sustainable value networks. In detail, the reach and ambition of the DigiPrime mission will be further increased by integrating and evaluating DigiPrime Horizontal and Vertical services in a completely distinct pilot from those already considered. 
          </p>
          <iframe width="720" height="405" src="https://www.youtube.com/embed/87qPXky-omA?si=mRl7eJ8C9KgsZD7B" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style={{paddingTop: 10 }}></iframe>
          <p style={{paddingTop: 40 }}>
          With more than 11 trillion EUR in public contracts for goods and services in 2020, public contracting is the largest market in the world, according to the OCP. According to Eurostat, the annual value of public procurement in the EU is roughly 2 trillion EUR (equal to the French GDP in 2020). Thus, there is enormous business potential in assisting decision-makers in public institutions to increase the profitability and ecological impact of end-of-life products.
          </p>
          <figure className="ue-horizon-logo">
              <img
             src={"/horizon-2020.jpeg"}
              />
              <figcaption>This project has received cascading funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement No 873111.
              </figcaption>
            </figure>
        </div>
      </article>
    </Layout>
  )
}

export default DigiPrime;

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`   